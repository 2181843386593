.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  align-items: center;
  background-color: #b4cbd4;
  background-image: url(./images/flowers.jpg);
  background-repeat: no-repeat;
}
.desktop {
  background-position: center;
  background-size: contain;
  white-space: nowrap;
}
.mobile {
  background-size: cover;
  background-position: 10%;
  text-align: center;
}

.header {
  display: flex;
  justify-content: center;
  font-size: 96px;
  font-weight: 800;
  color: white;
  margin: 0;
}

.footer {
  width: 100vw;
  padding: 0 0 10px 0;
  display: flex;
  justify-content: center;
}
